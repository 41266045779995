@import "../../../../src/scss/vendor/foundation/foundation_settings";
@import "../../../../src/scss/abstracts/custom_variables";
@import "../../../../src/scss/abstracts/custom_mixins";

.block_hero_small {
    padding: 40px 0;

    @include breakpoint(xlarge){
        padding: 48px 0;
    }

    .heading-h1 {
        margin-bottom: 24px;
    }

    .button {
        margin-top: 8px;
    }
}

;@import "sass-embedded-legacy-load-done:222";